import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../../helper";
import {mediaBaseURL} from "../../../utils/constant";
import HtmlParser from "react-html-parser";

function DigitalSolution({data}) {
    return (
        <section className="p-100">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10}>
                        <div className="example" data-text="Development">
                            <h2>{checkData(data, 'digital_solution_title')}</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={7} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'digital_solution_description'))}</div>
                    </Col>
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="offset-lg-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'digital_solution_iamge')}`}
                            className="img-fluid"
                            alt={checkData(data, 'digital_solution_title')}/>
                    </Col>

                </Row>
            </Container>
        </section>
    );
}

export default DigitalSolution;