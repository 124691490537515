import React, {useEffect, useState} from 'react';
import Seo from "../../layout/seo";
import {checkData} from "../../../helper";
import Layout from "../../layout/layout";
import Loader from "../../components/Loader";
import Banner from "./banner";
import Breadcrumbs from "../../components/breadcrumbs";
import axios from "axios";
import {baseURL} from "../../../utils/constant";
import Toprated from "./toprated";
import LocationPin from "./location_pin";
import ExpertDeveloper from "./expert_developer";
import DigitalSolution from "./digital_solution";
import GrowthPlane from "./growth_plane";
import Discovers from "./discovers";
import Workwith from "./workwith";
import Developmentprocess from "./developmentprocess";
import CustomSolution from "./custom_solution";
import Featured from "../../home/featured";
import Partner from "../../home/partner";


function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/locationgcc/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);

    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Locations" link3="GCC"/>
                    <Banner data={userData}/>
                    <Toprated data={userData}/>
                    <LocationPin data={userData}/>
                    <ExpertDeveloper data={userData}/>
                    <DigitalSolution data={userData}/>
                    <GrowthPlane data={userData}/>
                    <Developmentprocess data={userData}/>
                    <Discovers data={userData}/>
                    <Workwith data={userData}/>
                    <CustomSolution data={userData}/>
                    <Featured/>
                    <Partner bg="bg"/>
                </>
            }
        </Layout>
    );
}

export default Index;