import React from 'react';
import {mediaBaseURL} from "../../../utils/constant";
import {checkData} from "../../../helper";
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";

function Discovers({data}) {
    return (
        <section className="tekrevol_dark_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={7} lg={7} xl={7} xxl={7} className="mx-auto text-center">
                        <div className="example center" data-text="Success!">
                            <h2>{checkData(data, 'discover_title')}</h2>
                        </div>
                        <Link to={checkData(data, 'discover_btn_url')} className="border_btn mt-0">
                            {checkData(data, 'discover_btn_txt')}
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Discovers;