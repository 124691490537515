import React from 'react';
import {mediaBaseURL} from "../../../utils/constant";
import {checkData} from "../../../helper";
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from "react-html-parser";

function Workwith({data}) {
    return (
        <section className="work_with p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={7} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text="Tekrevol Work">
                            <h2>{checkData(data, 'work_with_tek_title')}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'work_with_tek_description'))}</div>
                    </Col>
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="my-auto text-center offset-lg-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'work_with_tek_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'work_with_tek_title')}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Workwith;