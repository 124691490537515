import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../../helper";
import HtmlParser from "react-html-parser";
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';


function Developmentprocess({data}) {
    const [activeTab, setActiveTab] = useState(0);

    function displayTab(tabNumber) {
        setActiveTab(tabNumber);
    }

    return (
        <section className="developmemt_proces p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10}>
                        <div className="example" data-text="App Process">
                            <h2>{checkData(data, 'development_process_title')}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'development_process_description'))}</div>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="swiper_main">

                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={0}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 4,
                                },
                                1200: {
                                    slidesPerView: 4,
                                },
                                900: {
                                    slidesPerView: 3,
                                },
                                500: {
                                    slidesPerView: 2,
                                },
                                100: {
                                    slidesPerView: 1,
                                }
                            }}

                        >
                            {data?.development_process_tabs?.map((value, i) => (
                                <SwiperSlide key={i} onClick={() => {
                                    displayTab(i)
                                }} className={`tabs ${activeTab === i ? 'active' : ''}`}>
                                    <span>
                                        {HtmlParser(checkData(value, 'title'))}
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>

                        {data?.development_process_tabs?.map((value, i) => (
                            <div
                                className={activeTab === i ? 'd-block tab_txt' : 'd-none tab_txt'}>
                                {HtmlParser(checkData(value, 'description'))}
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    )
        ;
}

export default Developmentprocess;