import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Bannerfrom from "../../components/bannerfrom";
import {checkData} from "../../../helper";
import HtmlParser from "react-html-parser";

function Banner({data}) {
    return (
        <div className="services_banner">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={7} xl={7} xxl={7} className="my-auto">
                        <div className="banenr_content">
                            <div className="example" data-text="Bahrain">
                                <h1>{HtmlParser(checkData(data, 'banner_title'))}</h1>
                            </div>
                            <div className="main_para">{HtmlParser(checkData(data, 'banner_description'))}</div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto">
                        <Bannerfrom title={`Book a Free <span>Consultation</span>`}/>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default Banner;