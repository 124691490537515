import React from 'react';
import {Col, Container, Row, Tab, Nav} from "react-bootstrap";
import {checkData} from "../../../helper";
import HtmlParser from "react-html-parser";


function CustomSolution({data}) {
    return (
        <section className="tekgrip_section tekrevol_dark_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10} className="my-auto">
                        <div className="example" data-text="Businesses">
                            <h2>{checkData(data, 'custom_solution_title')}</h2>
                        </div>
                        <div
                            className="main_para">{HtmlParser(checkData(data, 'custom_solution_description'))}</div>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekgrip_tabs">
                        <Tab.Container id="" defaultActiveKey="0">
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <Nav variant="pills" className="flex-column">
                                        {data?.custom_solution_tabs?.map((value, i) => (
                                            <Nav.Item key={i}>
                                                <Nav.Link eventKey={i}>{value.title}</Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <Tab.Content>
                                        {data?.custom_solution_tabs?.map((value, i) => (
                                            <Tab.Pane eventKey={i} key={i}>
                                                <div>{HtmlParser(checkData(value, 'description'))}</div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CustomSolution;